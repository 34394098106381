import React from "react";
import {Link} from "react-router-dom";

import socialData from "../Data/SocialData";

import DiscordIcon from "./Icons/DiscordIcon";
import SpotifyIcon from "./Icons/SpotifyIcon";
import YoutubeIcon from "./Icons/YoutubeIcon";
import InstagramIcon from "./Icons/InstagramIcon";
import TwitterIcon from "./Icons/TwitterIcon";

function Content() {

    const [newestEpisodeInProcess, setNewestEpisodeInProcess] = React.useState(false);

    const onNewestEpisodeClicked = async (): Promise<void> => {

        if (newestEpisodeInProcess){
            return;
        }

        setNewestEpisodeInProcess(true);

        let url: string = socialData.spotify;

        try {
            const response: Response = await fetch("https://api.powersindustries.com/v1/spotify/latest_episode", {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error("Error fetching episode");
            }

            const openUrl: string = "https://open.spotify.com";
            const result = await response.json();
            if (result.status && result.status === "success") {
                url = openUrl + result.data.uri.replaceAll(':', '/').replaceAll("spotify", "");
            } else {
                console.log("Latest episode endpoint failed: " + result.error);
            }

        } catch (error) {
            console.error("Latest episode endpoint failed:", error);
        }

        window.open(url, "_blank");

        setNewestEpisodeInProcess(false);
    }

    function onEmailCopyClicked(): void {
        window.location.href = `mailto:${socialData.email}`;
    }

    return (

        <div>

            {/* Banner */}
            <img src="/images/WildDogsBanner.png" alt="banner" className="banner"/>


            {/* Listening Links */}
            <h2>Check Out the Newest Episode Below</h2>
            <button
                onClick={onNewestEpisodeClicked}>
                <h3>Newest Episode</h3>
            </button>


            {/* Follow Links */}
            <h2>Where to Follow</h2>

            <Link
                to={socialData.spotify}
                target="_blank">

                <SpotifyIcon styling="icon"/>

            </Link>

            <Link
                to={socialData.youtube}
                target="_blank">

                <YoutubeIcon styling="icon"/>

            </Link>

            <Link
                to={socialData.discord}
                target="_blank">

                <DiscordIcon styling="icon"/>

            </Link>

            <Link
                to={socialData.twitter}
                target="_blank">

                <TwitterIcon styling="icon"/>
            </Link>

            <Link
                to={socialData.twitter}
                target="_blank">

                <InstagramIcon styling="icon"/>
            </Link>


            {/* Email Contact */}
            <h2>Contact Us Via Email</h2>
            <button
                onClick={onEmailCopyClicked}>
                <h3>Email Us</h3>
            </button>


            {/* Moxfield */}
            <h2>Moxfield Deck Lists</h2>

            <div className="moxfield-links-div">

                {/* Leland */}
                <div className="moxfield-links-info">
                    <h3>Leland</h3>
                    <Link
                        to={socialData.lelandMoxfield}
                        target="_blank">

                        <img src="/images/moxfieldLogo.png" alt="moxfield" className="icon"/>

                    </Link>
                </div>

                {/* Luke  */}
                <div className="moxfield-links-info">
                    <h3>Luke</h3>
                    <Link
                        to={socialData.lukeMoxfield}
                        target="_blank">

                        <img src="/images/moxfieldLogo.png" alt="moxfield" className="icon"/>

                    </Link>

                </div>

            </div>

        </div>

    );

}

export default Content;
