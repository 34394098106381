import React from "react";
import {Link} from "react-router-dom";

import socialData from "../Data/SocialData";
import TwitterIcon from "./Icons/TwitterIcon";
import InstagramIcon from "./Icons/InstagramIcon";
import SpotifyIcon from "./Icons/SpotifyIcon";
import YoutubeIcon from "./Icons/YoutubeIcon";

function Header() {

    return (

        <header>

            <Link
                to={socialData.twitter}
                target="_blank">

                <TwitterIcon styling="header-icon"/>

            </Link>

            <Link
                to={socialData.instagram}
                target="_blank">

                <InstagramIcon styling="header-icon"/>

            </Link>

            <img src="/images/WildDogsIcon.png" alt="banner" className="logo"/>

            <Link
                to={socialData.spotify}
                target="_blank">

                <SpotifyIcon styling="header-icon"/>

            </Link>

            <Link
                to={socialData.youtube}
                target="_blank">

                <YoutubeIcon styling="header-icon"/>

            </Link>

        </header>

    );

}

export default Header;