import React from 'react';
import Header from "./View/Header";
import Content from "./View/Content";
import Footer from "./View/Footer";

function App() {


    return (
        <div>

            <Header/>
            <Content/>
            <Footer/>

        </div>
    );


}

export default App;
