import React from "react";

function Footer() {
    const currYear = new Date().getFullYear();
    return (
        <footer>
            <p>© {currYear} by Wild Dogs Podcast</p>
        </footer>
    );
}

export default Footer;