const socialData =
    {
        "twitter": "https://twitter.com/wilddogspod",
        "discord": "https://discord.gg/6FxwRwUtK7",
        "instagram": "http://instagram.com/wilddogspod",
        "spotify": "https://open.spotify.com/show/4VYrLLsN9eSm0uPzwWva2A",
        "youtube": "https://www.youtube.com/@WildDogsPod",
        "lelandMoxfield": "https://www.moxfield.com/users/Trilorim",
        "lukeMoxfield": "https://www.moxfield.com/users/MrDaedalus",
        "email": "wilddogspodcast@gmail.com",
        "newestSpotifyEpisode": "https://open.spotify.com/episode/4WIHLDBUMfCxwkKemIDss4",

    };

export default socialData;